
import {
  computed,
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeMount,
  watch,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import ChatsMessage from "@/pages/console/Chats/ChatsMessage/ChatsMessage.vue";
import { cloneDeep } from "lodash";
import ChatsRtmRejoinModal from "@/pages/console/Chats/ChatsRtmRejoinModal/ChatsRtmRejoinModal.vue";

// 화면 진입시 기존 - 채팅 데이터 초기화 시키기;
export default defineComponent({
  name: "ChatsMessages",
  components: {
    ChatsMessage,
    ChatsRtmRejoinModal,
  },
  props: {
    badgeCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ["resetBadgeCount", "rtmRejoin"],
  setup(props, { emit }) {
    const chatsMessagesElement = ref();
    const store = useStore();

    const state = reactive({
      chat: computed(() => {
        return store.getters["chats/chat"];
      }),
      chatMessages: computed(() => {
        return store.getters["chats/messages"];
      }),
      badgeCount: 0,
      initchatMessages: [],
      //TODO 에러발생 코드 수정 필요
      unreadMessageResourceId: computed(() => {
        return state.passedBadgeCount > 0 && state.initchatMessages.length > 0
          ? state.initchatMessages[
              state.initchatMessages.length - state.passedBadgeCount
            ].resourceId
          : "";
      }),
      isRtmDisconnected: computed(() => {
        return store.getters["chats/isRtmDisconnected"];
      }),
      isRtmRejoinModal: true,
      passedBadgeCount: 0,
    });

    watch(
      () => state.chatMessages,
      async (chatMessages, prevChatMessages) => {
        state.badgeCount = 0;
        scrollToEnd(state.badgeCount);
      }
    );

    onBeforeMount(() => {
      state.badgeCount = props.badgeCount;
      let json = JSON.stringify({ badgeCount: props.badgeCount });
      let jsonParse = JSON.parse(json);
      state.passedBadgeCount = jsonParse.badgeCount;
      state.initchatMessages = cloneDeep(state.chatMessages);
    });

    onMounted(() => {
      if (chatsMessagesElement.value) {
        scrollToEnd(state.badgeCount);
      }
    });

    //todo 스크롤 고도화
    const scrollToEnd = (badgeCounter) => {
      if (
        badgeCounter &&
        chatsMessagesElement.value.querySelector(".unread-message")
      ) {
        chatsMessagesElement.value.scrollTop =
          chatsMessagesElement.value.querySelector(".unread-message")
            .offsetTop - 12;
      } else {
        chatsMessagesElement.value.scrollTop =
          chatsMessagesElement.value.scrollHeight;
      }
    };

    const actions = {
      imageLoaded: () => {
        scrollToEnd(state.badgeCount);
      },
      getChats: () => {
        store.dispatch("chats/getChats");
      },
      rtmRejoin: () => {
        emit("rtmRejoin");
      },
      closeRtmRejoinModal: () => {
        state.isRtmRejoinModal = false;
      },
    };

    return {
      state,
      actions,
      chatsMessagesElement,
    };
  },
});
