
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import ChatItem from "@/pages/console/Chats/ChatItem/ChatItem.vue";
import ChatView from "@/pages/console/Chats/ChatView/ChatView.vue";
import ListIcon from "@/components/console/icons/ListIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ChatsListModal from "@/pages/console/Chats/ChatsListModal/ChatsListModal.vue";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import { cloneDeep } from "lodash";
import PageLoading from "@/components/console/loadings/PageLoading.vue";

export default defineComponent({
  name: "Chats",
  components: {
    PageLoading,
    InputSearchNew,
    ChatView,
    PageHeader,
    ChatItem,
    ButtonBasic,
    ListIcon,
    ChatsListModal,
  },
  setup() {
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      chats: computed(() => {
        if (state.q.trim().length > 0) {
          let chats = cloneDeep(store.getters["chats/chats"]);
          let selectedChats = chats.filter((item) =>
            item.title.includes(state.q)
          );
          return selectedChats;
        } else {
          return store.getters["chats/chats"];
        }
      }),
      selectedChat: "",
      chatBadgeCount: 0,
      isChatLoading: false,
      showChatsListModal: false,
      q: "",
      filteredChatsItem: "",
      intervalGetChats: null,
      pageLoading: true,
    });

    onBeforeMount(async () => {
      store.dispatch("chats/getChats").then(() => {
        state.pageLoading = false;
      });
      state.intervalGetChats = setInterval(() => {
        store.dispatch("chats/getChats");
      }, 10000);
    });

    const actions = {
      enterToChat: (chatResourceId, chatBadgeCount) => {
        state.chatBadgeCount = chatBadgeCount;
        state.selectedChat = chatResourceId;
      },
      getChat: async () => {
        if (state.selectedChat) {
          state.isChatLoading = true;
          await store.dispatch("chats/getChat", {
            chatResourceId: state.selectedChat,
          });
          await store.dispatch("chats/getChatMessages", {
            chatResourceId: state.selectedChat,
          });

          await store
            .dispatch("chats/startRtm", {
              chatResourceId: state.selectedChat,
            })
            .then(() => {
              state.isChatLoading = false;
            });
        }
      },
      sendChat: (value) => {
        let payload = {
          chatResourceId: state.selectedChat,
          data: { message: value },
        };
        store.dispatch("chats/postChatMessages", payload);
      },
      sendAttachment: (value) => {
        console.log("=========sendAttachment==========");
        let payload = {
          chatResourceId: state.selectedChat,
          data: value,
        };
        store.dispatch("chats/postChatUpload", payload);
      },
      postChatsView: (resourceId) => {
        let payload = {
          chatResourceId: resourceId,
        };
        store.dispatch("chats/postChatsView", payload);
      },
      openChatsListModal: () => {
        state.showChatsListModal = true;
      },
      closeChatsListModal: () => {
        state.showChatsListModal = false;
      },
      resetBadgeCount: () => {
        state.chatBadgeCount = 0;
      },
      leaveChat: (resourceId) => {
        store.dispatch("chats/logoutChat", {
          chatResourceId: resourceId,
        });
      },
    };

    onBeforeUnmount(() => {
      if (state.selectedChat) {
        actions.leaveChat(state.selectedChat);
      }
      clearInterval(state.intervalGetChats);
    });

    return { state, actions };
  },
});
