import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fe35727"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "chatsMessagesElement",
  class: "chats-messages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_message = _resolveComponent("chats-message")!
  const _component_chats_rtm_rejoin_modal = _resolveComponent("chats-rtm-rejoin-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.chatMessages, (message, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `message-${index}`
        }, [
          _createVNode(_component_chats_message, {
            "chat-message": message,
            "badge-count": _ctx.state.passedBadgeCount,
            "unread-message-resource-id": _ctx.state.unreadMessageResourceId,
            "prev-user-resource-id": 
          index > 0 ? _ctx.state.chatMessages[index - 1].user.resourceId : ''
        ,
            "next-user-resource-id": 
          index > 0 && index < 99
            ? _ctx.state.chatMessages[index + 1]?.user.resourceId
            : ''
        ,
            onImageLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.imageLoaded()))
          }, null, 8, ["chat-message", "badge-count", "unread-message-resource-id", "prev-user-resource-id", "next-user-resource-id"])
        ]))
      }), 128))
    ], 512),
    (_openBlock(), _createBlock(_Teleport, { to: "#teleport" }, [
      (_ctx.state.isRtmDisconnected && _ctx.state.isRtmRejoinModal)
        ? (_openBlock(), _createBlock(_component_chats_rtm_rejoin_modal, {
            key: 0,
            onRtmRejoin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.rtmRejoin())),
            onHideModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.closeRtmRejoinModal()))
          }))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}