
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";
import Avatar from "@/components/console/avatars/Avatar.vue";
import helper from "@/helper";
import { dateDisplay } from "@/helper/date";
import fileIcon from "@/components/console/icons/FileIcon.vue";

export default defineComponent({
  name: "ChatsMessage",
  components: { Avatar, fileIcon },
  props: {
    chatMessage: {
      type: Object,
      required: true,
    },
    prevUserResourceId: {
      type: String,
      required: false,
    },
    nextUserResourceId: {
      type: String,
      required: false,
    },
    unreadMessageResourceId: {
      type: String,
      required: false,
    },
    badgeCount: {
      type: Number,
      required: false,
    },
  },
  emits: ["imageLoad"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      userName: computed(() => {
        return helper.displayUserName(props.chatMessage.user);
      }),
      isMyMessage: computed(() => {
        return state.user?.resourceId === props.chatMessage.user?.resourceId;
      }),
      createdAt: computed(() => {
        let time = props.chatMessage.createdAt;
        return dateDisplay(time, "YYYY-MM-DD hh:mm");
      }),
      isPreviousSameSender: computed(() => {
        let resourceId = props.prevUserResourceId;
        return resourceId && resourceId === props.chatMessage.user.resourceId
          ? "is-previous-same-sender"
          : "";
      }),
      isNextOtherSender: computed(() => {
        let resourceId = props.nextUserResourceId;
        return resourceId && resourceId !== props.chatMessage.user.resourceId
          ? "is-next-other-sender"
          : "";
      }),
      attachmentFileType: computed(() => {
        let attachment = props.chatMessage.attachment;
        if (attachment.mimeType.includes("image/")) {
          return "imageFile";
        } else {
          return "etcFile";
        }
      }),
    });

    const actions = {
      imageLoad: () => {
        emit("imageLoad");
      },
    };

    return { state, actions, moment };
  },
});
