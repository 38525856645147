import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bf9ed01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container-new chats-page" }
const _hoisted_2 = { class: "mo" }
const _hoisted_3 = { class: "chats-list-view" }
const _hoisted_4 = { class: "bg-gray-01 chats-list pc" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "body" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_loading = _resolveComponent("page-loading")!
  const _component_list_icon = _resolveComponent("list-icon")!
  const _component_button_basic = _resolveComponent("button-basic")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_input_search_new = _resolveComponent("input-search-new")!
  const _component_chat_item = _resolveComponent("chat-item")!
  const _component_chat_view = _resolveComponent("chat-view")!
  const _component_chats_list_modal = _resolveComponent("chats-list-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.pageLoading)
      ? (_openBlock(), _createBlock(_component_page_loading, {
          key: 0,
          width: "100"
        }))
      : _createCommentVNode("", true),
    (!_ctx.state.pageLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_header, { title: "채팅" }, {
            headerRightSide: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_button_basic, {
                  "bg-color": "transparent",
                  padding: "0",
                  onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.openChatsListModal()))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_list_icon, {
                      width: "24",
                      height: "24"
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_input_search_new, {
                  modelValue: _ctx.state.q,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.q) = $event)),
                  "search-type": "typingText",
                  shape: "underline",
                  placeholder: "타이틀을 입력해주세요."
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.chats, (chat, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `chat-${index}`,
                    class: _normalizeClass({ active: _ctx.state.selectedChat === chat.resourceId }),
                    onClick: ($event: any) => (_ctx.actions.enterToChat(chat.resourceId, chat.badgeCount))
                  }, [
                    _createVNode(_component_chat_item, { chat: chat }, null, 8, ["chat"])
                  ], 10, _hoisted_7))
                }), 128))
              ])
            ]),
            _createVNode(_component_chat_view, {
              "chat-resource-id": _ctx.state.selectedChat,
              "is-loading": _ctx.state.isChatLoading,
              "badge-count": _ctx.state.chatBadgeCount,
              onGetChat: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.getChat())),
              onSendChat: _cache[3] || (_cache[3] = (value) => _ctx.actions.sendChat(value)),
              onSendAttachment: _cache[4] || (_cache[4] = (value) => _ctx.actions.sendAttachment(value)),
              onPostChatsView: _cache[5] || (_cache[5] = (value) => _ctx.actions.postChatsView(value)),
              onResetBadgeCount: _cache[6] || (_cache[6] = ($event: any) => (_ctx.actions.resetBadgeCount())),
              onChangeChat: _cache[7] || (_cache[7] = (value) => _ctx.actions.leaveChat(value))
            }, null, 8, ["chat-resource-id", "is-loading", "badge-count"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "#teleport" }, [
      (_ctx.state.showChatsListModal)
        ? (_openBlock(), _createBlock(_component_chats_list_modal, {
            key: 0,
            "selected-chat-resource-id": _ctx.state.selectedChat,
            onCloseModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.actions.closeChatsListModal())),
            onEnterToChat: _cache[9] || (_cache[9] = 
          (chatResourceId, chatBadgeCount) =>
            _ctx.actions.enterToChat(chatResourceId, chatBadgeCount)
        )
          }, null, 8, ["selected-chat-resource-id"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}