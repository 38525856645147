import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a89d1bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "unread-message b-text-3"
}
const _hoisted_2 = { class: "message-avatar" }
const _hoisted_3 = { class: "name-message" }
const _hoisted_4 = { class: "message" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "image"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "file-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_file_icon = _resolveComponent("file-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.badgeCount > 0 && _ctx.unreadMessageResourceId === _ctx.chatMessage.resourceId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 읽지 않은 채팅 "))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["chats-message", [_ctx.state.isMyMessage ? 'my-message' : '']])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_avatar, {
          class: _normalizeClass(["mo", `${_ctx.state.isPreviousSameSender}`]),
          size: "28",
          "avatar-img": _ctx.chatMessage.user.avatar
        }, null, 8, ["avatar-img", "class"]),
        _createVNode(_component_avatar, {
          class: _normalizeClass(["pc", `${_ctx.state.isPreviousSameSender}`]),
          size: "45",
          "avatar-img": _ctx.chatMessage.user.avatar
        }, null, 8, ["avatar-img", "class"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["name", `${_ctx.state.isPreviousSameSender}`])
        }, _toDisplayString(_ctx.state.userName), 3),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.chatMessage.text)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "text text-gray-reverse",
                innerHTML: _ctx.chatMessage.text
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.chatMessage.attachment)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.state.attachmentFileType === 'imageFile')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("img", {
                        src: _ctx.chatMessage.attachment.url,
                        alt: "sended image",
                        onLoad: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.actions.imageLoad()), ["stop"]))
                      }, null, 40, _hoisted_7)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.state.attachmentFileType === 'etcFile')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("a", {
                        class: "text text-gray-reverse file-link",
                        href: _ctx.chatMessage.attachment.url,
                        target: "_blank"
                      }, [
                        _createElementVNode("span", _hoisted_10, [
                          _createVNode(_component_file_icon, {
                            width: "20",
                            height: "20",
                            "fill-color": "#ffffff"
                          })
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.chatMessage.attachment.name) + " | " + _toDisplayString(_ctx.chatMessage.attachment.size), 1)
                      ], 8, _hoisted_9)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["time", `${_ctx.state.isNextOtherSender} ${_ctx.state.isPreviousSameSender}`])
      }, _toDisplayString(_ctx.state.createdAt), 3)
    ], 2)
  ], 64))
}