
import { computed, defineComponent, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import ChatsMessages from "@/pages/console/Chats/ChatsMessages/ChatsMessages.vue";
import swal from "@/helper/swal";
import ChatsLimitFileInfoModal from "../ChatsLimitFileInfoModal/ChatsLimitFileInfoModal.vue";

// 화면 진입시 기존 - 채팅 데이터 초기화 시키기;
export default defineComponent({
  name: "ChatView",
  components: {
    ChatsMessages,
    PlusIcon,
    ButtonBasic,
    PageLoading,
    ChatsLimitFileInfoModal,
  },
  props: {
    chatResourceId: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    badgeCount: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "getChat",
    "sendChat",
    "sendAttachment",
    "postChatsView",
    "resetBadgeCount",
    "changeChat",
  ],
  setup(props, { emit }) {
    const inputElement = ref(null);
    const textAreaElement = ref();
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      isLoading: computed(() => {
        return props.isLoading;
      }),
      chat: computed(() => {
        return store.getters["chats/chat"];
      }),
      bigcChatBot: computed(() => {
        return state.chat.host.resourceId === "1PK4RKQZG8";
      }),
      chatResourceId: computed(() => {
        return store.getters["chats/chat"].resourceId
          ? store.getters["chats/chat"].resourceId
          : null;
      }),
      initMessage: true,
      textMessage: "",
      saveDisabled: computed(() => {
        return state.textMessage.trim().length === 0;
      }),
      textareaInitialHeight: 0,
      showChatsLimitFileFormatModal: false,
      showChatsLimitFileSizeModal: false,
    });

    let textareaInitialHeight = 0;

    watch(
      () => props.chatResourceId,
      async (resourceId, prevResourceId) => {
        // 첫 채팅
        if (prevResourceId.length === 0) {
          await emit("postChatsView", resourceId);
          await emit("getChat");
          if (state.initMessage) {
            state.initMessage = false;
          }
        }

        // 첫 채팅 이후 채팅창 변경 시
        if (prevResourceId.length > 0 && resourceId !== prevResourceId) {
          await emit("changeChat", prevResourceId);
          await emit("postChatsView", resourceId);
          await emit("getChat");
        }
      }
    );
    // todo 방을 나갈 때 post 메시지 싸야함.

    const saveComment = () => {
      if (state.saveDisabled) {
        return;
      }
      emit("sendChat", state.textMessage);
    };

    const initComment = (e = null) => {
      if (e) {
        e.target.value = "";
      }
      state.textMessage = "";

      //note 텍스트 영역의 높이 초기화
      textAreaElement.value.style.height = textareaInitialHeight;
    };

    const onUploadFile = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      //note 용량 제한 50MB
      let maximumByte = 50000000;
      if (maximumByte < file.size) {
        state.showChatsLimitFileSizeModal = true;
        return;
      }

      const allowedFileTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/mp4",
        "video/avi",
        "video/quicktime",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/zip",
        "application/x-rar",
        "",
      ];

      if (!allowedFileTypes.includes(file.type)) {
        state.showChatsLimitFileFormatModal = true;
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      emit("sendAttachment", formData);
    };

    const heightResize = () => {
      textAreaElement.value.style.height = "auto";

      let lineHeight = parseFloat(
        getComputedStyle(textAreaElement.value).lineHeight
      );

      let paddingTopBottomWidth = Number(
        getComputedStyle(textAreaElement.value).paddingTop.replace("px", "")
      );

      let borderWidth = Number(
        getComputedStyle(textAreaElement.value).borderWidth.replace("px", "")
      );

      textAreaElement.value.style.height = `${
        lineHeight + (paddingTopBottomWidth + borderWidth) * 2
      }px`;

      //note 텍스트 영역의 초기 높이 값 저장
      textareaInitialHeight = textAreaElement.value.style.height;

      let line = Number(
        (
          (textAreaElement.value.scrollHeight - paddingTopBottomWidth * 2) /
          lineHeight
        ).toFixed(1)
      );

      if (line === 1) {
        textAreaElement.value.style.height = `${
          lineHeight + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      } else if (line > 7) {
        textAreaElement.value.style.height = `${
          lineHeight * 7 + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      } else {
        textAreaElement.value.style.height = `${
          line * lineHeight + (paddingTopBottomWidth + borderWidth) * 2
        }px`;
      }
    };

    const actions = {
      updateComment: (e) => {
        state.textMessage = e.target.value;
        heightResize();
      },
      onEnterInitComment: (e) => {
        initComment(e);
      },
      onEnterSaveComment: () => {
        saveComment();
      },
      onClickSaveComment: () => {
        saveComment();
        initComment();
      },
      openUploadFile: () => {
        inputElement.value.click();
      },
      resetBadgeCount: () => {
        emit("resetBadgeCount");
      },
      rtmRejoin: () => {
        store.dispatch("chats/getChat", {
          chatResourceId: props.chatResourceId,
        });
        store.dispatch("chats/rtmJoin", props.chatResourceId).then(() => {
          swal.createCompleteToast("연결되었습니다.");
        });
      },
      closeChatsLimitFileSizeModal: () => {
        state.showChatsLimitFileSizeModal = false;
      },
      closeChatsLimitFileFormatModal: () => {
        state.showChatsLimitFileFormatModal = false;
      },
    };

    return {
      state,
      actions,
      inputElement,
      onUploadFile,
      textAreaElement,
    };
  },
});
