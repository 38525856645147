<template>
  <base-modal-new
    title="서버와 연결이 끊어졌어요"
    sub-title="채팅 정보를 가져오는 중에 문제가 발생했습니다. 재접속 해주세요."
    :is-close-btn="false"
  >
    <template #modalBody>
      <div class="btn-wrapper">
        <button-text
          class="confirm-btn rtm-rejoin-btn"
          text="재접속 하기"
          text-size="s1"
          color="#ffffff"
          @click="$emit('rtmRejoin')"
        ></button-text>
      </div>
    </template>
  </base-modal-new>
</template>

<script>
import BaseModalNew from "../../../../components/common/BaseModalNew.vue";
import ButtonText from "../../../../components/console/buttons/ButtonText";

export default {
  name: "ChatsRtmRejoinModal.vue",
  components: { ButtonText, BaseModalNew },
  emits: ["hideModal", "rtmRejoin"],
};
</script>

<style scoped>
.btn-wrapper {
  margin-top: 32px;
}

.confirm-btn {
  width: 100%;
  background-color: #8e1eff;
}

.rtm-rejoin-btn {
  justify-content: center;
}
</style>
