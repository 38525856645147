import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e06e192"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chat-item"
}
const _hoisted_2 = { class: "chat-name-message" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "chat-time-counter" }
const _hoisted_6 = { class: "time" }
const _hoisted_7 = { class: "message-counter bg-red-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_ctx.chat)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_avatar, {
          class: "chat-avatar",
          size: "24",
          "avatar-img": _ctx.state.chatUser?.avatar
        }, null, 8, ["avatar-img"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.state.chatUser?.name), 1),
          _createElementVNode("div", {
            class: "message text-truncate--row-2",
            innerHTML: 
          _ctx.chat.latestMessage.attachment
            ? _ctx.chat.latestMessage.attachment.name
            : _ctx.chat.latestMessage.text
        
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.state.latestMessageCreatedAt), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.chat.badgeCount), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}