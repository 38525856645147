<template>
  <base-modal-chat class="chats-list-modal" @closeModal="actions.closeModal()">
    <template #modalTitle>
      <input-search-new
        v-model="state.q"
        class="filter-chats"
        shape="underline"
        placeholder="타이틀을 입력해주세요."
      ></input-search-new>
    </template>
    <template #modalBody>
      <ul class="body">
        <li
          v-for="(chat, index) in state.chats"
          :key="`chat-${index}`"
          :class="{ active: selectedChatResourceId === chat.resourceId }"
          @click="actions.enterToChat(chat.resourceId, chat.badgeCount)"
        >
          <chat-item :chat="chat"></chat-item>
        </li>
      </ul>
    </template>
  </base-modal-chat>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import BaseModalChat from "@/components/console/chats/BaseModalChat.vue";
import ChatItem from "@/pages/console/Chats/ChatItem/ChatItem";
import InputSearchNew from "@/components/console/inputs/InputSearchNew";
import { cloneDeep } from "lodash";
import { useStore } from "vuex";

export default defineComponent({
  name: "ChatsListModal",
  components: { BaseModalChat, ChatItem, InputSearchNew },
  props: {
    chats: {
      types: Array,
      required: true,
    },
    selectedChatResourceId: {
      types: String,
      required: false,
    },
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      q: "",
      chats: computed(() => {
        if (state.q.trim().length > 0) {
          let chats = cloneDeep(store.getters["chats/chats"]);
          let selectedChats = chats.filter((item) => item.title === state.q);
          return selectedChats;
        } else {
          return store.getters["chats/chats"];
        }
      }),
    });

    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
      enterToChat: (resourceId, chatBadgeCount) => {
        emit("enterToChat", resourceId, chatBadgeCount);
        emit("closeModal");
      },
    };

    return { state, actions };
  },
});
</script>

<style src="./style.css" scoped></style>
