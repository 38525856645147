<template>
  <base-modal-new
    title="해당 파일을 보낼 수 없어요"
    :sub-title="subTitle"
    @closeModal="$emit('hideModal')"
  >
    <template #modalBody>
      <div class="btn-wrapper">
        <button-text
          class="confirm-btn btn-position"
          text="확인"
          text-size="s1"
          color="#ffffff"
          @click="$emit('hideModal')"
        ></button-text>
      </div>
    </template>
  </base-modal-new>
</template>

<script>
import BaseModalNew from "../../../../components/common/BaseModalNew.vue";
import ButtonText from "../../../../components/console/buttons/ButtonText";

export default {
  name: "ChatsLimitFileInfoModal",
  components: { ButtonText, BaseModalNew },
  props: {
    subTitle: {
      type: String,
      default: "보낼 수 없는 형식의 파일이예요",
    },
  },
  emits: ["hideModal"],
};
</script>

<style scoped>
.btn-wrapper {
  margin-top: 32px;
}

.confirm-btn {
  width: 100%;
  background-color: #8e1eff;
}

.btn-position {
  justify-content: center;
}
</style>
