
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import Avatar from "@/components/console/avatars/Avatar.vue";
import moment from "moment-timezone";
import { dateDisplay } from "@/helper/date";

export default defineComponent({
  name: "ChatItem",
  components: { Avatar },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      pageLoading: false,
      chatUser: computed(() => {
        let user = null;
        if (
          props.chat.guest &&
          store.getters["auth/user"]?.resourceId === props.chat.guest.resourceId
        ) {
          user = props.chat.host;
        }

        if (
          props.chat.host &&
          store.getters["auth/user"]?.resourceId === props.chat.host.resourceId
        ) {
          user = props.chat.guest;
        }
        return user;
      }),
      latestMessageCreatedAt: computed(() => {
        let date = new Date(props.chat.latestMessage.createdAt);
        if (props.chat.latestMessage) {
          moment(date).format("MM-DD hh:mm");
        }

        return props.chat.latestMessage
          ? dateDisplay(props.chat.latestMessage.createdAt, "YY.MM.DD HH:mm")
          : "--";
      }),
    });

    onBeforeMount(async () => {
      state.pageLoading = true;
    });

    const actions = {};

    return { state, actions, moment };
  },
});
